import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GrtSecurityLayout from '@/modules/common/views/layouts/security/security.layout.vue'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
  name: 'GtrRegisterCompanyView'
})
export default class GrtRegisterCompanyView extends Vue {
  @Ref()
  readonly observerRegisterCompanyForm!: InstanceType<typeof ValidationObserver>

  data () {
    return {
      submitting: false,
      company: {
        name: null,
        logo: null,
        number_of_participants: null,
        event_type: null
      },
      eventTypes: [
        {
          text: 'Conference',
          value: 'conference'
        },
        {
          text: 'Social',
          value: 'social'
        },
        {
          text: 'Cultural',
          value: 'cultural'
        },
        {
          text: 'Seminar',
          value: 'seminar'
        },
        {
          text: 'Trade Show',
          value: 'trade_show'
        },
        {
          text: 'Networking',
          value: 'networking'
        },
        {
          text: 'Other',
          value: 'other'
        }
      ]
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  async submit () {
    const form = this.$refs.registerCompanyForm as HTMLFormElement
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload = _.cloneDeep(this.$data.company)
        const response = await this.$store.dispatch('company/registerCompany', payload)
        this.$data.company = {
          name: null,
          logo: null,
          number_of_participants: null,
          event_type: null
        }
        this.$router.push({ name: 'level-two.company.dashboard', params: { uuid: response.uuid }, query: { greeting: 'welcome' } })
      } catch (error) {
        if (error.data) {
          const errors = error.data.errors
          if (errors) {
            this.showServerErrors(errors)
          }
        }
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  private showServerErrors (errors: Record<string, string>) {
    this.observerRegisterCompanyForm.setErrors(errors)
  }
}
